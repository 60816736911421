import {Center, Container, Flex, rem, SimpleGrid, Title} from "@mantine/core";
import {BioCard} from "./BioCard";
import holger from './images/Holger_Claussen_2024_1x1_lowres.jpg';
import kariem from './images/kariem.jpeg';
import michael from './images/micheal.jpeg';
import anja from './images/Anja_Claussen_2024_1x1_lowres.jpg';
import ger from './images/ger.JPG';
export default function OurTeam()
{

    const holgerBio =
        <div>
            Prof. Holger Claussen is Head of the Wireless Communications Laboratory at Tyndall National
            Institute, and Professor of Wireless Communications at University College Cork and Trinity College Dublin.
            Previously he led the Wireless Communications Research Department of Nokia Bell Labs Ireland and the US.
            He received the 2014 World Technology Award in the individual category Communications
            Technologies for innovative work of “the greatest likely long-term significance” for his contribution to Small Cells.
            Holger received his Ph.D. degree in signal processing for digital communications from the University of Edinburgh in the U.K. in 2004
            He is the author of the book "Small Cell Networks", more than 140 research publications, and 87 granted patent
            families. He is a Fellow of the IEEE, Fellow of the World Technology Network, and
            member of the IET.
        </div>

    const kariemBio =
        <div>
            Dr. Kariem Fahmi is the Lead Researcher on the HyperPath project in the Wireless Communications Laboratory at Tyndall National Institute.
            Previously he was a software architect at Nokia Bell Labs Ireland, working in the area of computer networks and communication protocols.
            He received his Ph.D. degree in Computer Science from Trinity College Dublin in Ireland in 2021, where his thesis focused on the design of
            novel communication protocols for multi-connectivity. Kariem has 8 granted patents in the area of computer networks, and over 15 years of
            software development experience.
        </div>

    const michaelBio =
        <div>
            Michael is an experienced multi-disciplinary professional with business, technical, health and entrepreneurial skills, and expertise in strategic engagement at the industry-academic interface. Michael is the founder of a number of startups, has been a director of several ICT companies and was most recently the Centre Director of Enterprise Ireland's technology centre in Applied Research for Connected Health.
        </div>

    const anjaBio =
        <div>
            Anja is an experienced sales and business development professional in the technology industry who has built and led successful sales teams at companies such as DocuSign, IBM and Bentley Systems for the past 10 years.
            Anja has an MBA from the University of Leicester in the UK and a Masters of Civil Engineering from Ruhr University Bochum in Germany.
        </div>

    const gerBio =
        <div>
            Ger is a seasoned commercial, technical and product leader having 34 years’ experience with startups and blue chip companies. Working from Director to CTO level, Ger has created and grown new lines of business in sectors such as Telecommunications, Industrial, Energy, Medical, Smart Buildings, Automotive, Life Sciences, Education and Retail
        </div>
    return (
      <div>


          <Container size="xl">
              <Title order={1}  mt="sm" ta="center" fw={700} mb={100} style={{fontSize:  rem(80) }} >
                  <p className="text-white">Our team</p>
              </Title>
              <Center>
                  <SimpleGrid cols={{base:1, md:2, lg:3}} spacing={50} className="w-fit">
                      <BioCard image={kariem}
                               name={"Dr. Kariem Fahmi"}
                               bio={kariemBio}
                               email="kariem.fahmi@tyndall.ie"
                               linkedin="https://www.linkedin.com/in/kariem-fahmi-0846a195/"
                                key={"kariem"}
                      />
                      <BioCard image={ger}
                                 name={"Ger McNamara"}
                                 bio={gerBio}
                                 email="ger_mc_namara@hotmail.com"
                                 linkedin="https://www.linkedin.com/in/germc/"
                               key={"ger"}
                        />
                      <BioCard image={holger}
                               name={"Prof. Holger Claussen"}
                               bio={holgerBio}
                               email="holger.claussen@tyndall.ie"
                               linkedin="https://www.linkedin.com/in/holgerclaussen/"
                               key={"holger"}

                      />
                      {/*<BioCard image={michael}*/}
                      {/*         name={"Michael O'Shea"}*/}
                      {/*         bio={michaelBio}*/}
                      {/*         email="michael.oshea@tyndall.ie"*/}
                      {/*         linkedin="https://www.linkedin.com/in/aboutmichaeloshea/"*/}
                      {/*         key={"michael"}*/}
                      {/*/>*/}

                      {/*<BioCard image={anja}*/}
                      {/*         name={"Anja Claussen"}*/}
                      {/*         bio={anjaBio}*/}
                      {/*         email="anja@hyperpath.ie"*/}
                      {/*         linkedin="https://www.linkedin.com/in/anjaclaussen/"*/}
                      {/*          key={"anja"}*/}
                      {/*/>*/}

                  </SimpleGrid>
              </Center>
          </Container>
      </div>
    );
}